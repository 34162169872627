import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as S from "./styled"

const Pagination = ({
  isFirst,
  isLast,
  currentPage,
  numPages,
  prevPage,
  nextPage,
}) => {
  return (
    <S.PaginationWrapper>
      {!isFirst && (
        <AniLink
          to={prevPage}
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          ← Página Anterior
        </AniLink>
      )}
      <p>
        {currentPage} de {numPages}
      </p>
      {!isLast && (
        <AniLink
          to={nextPage}
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          Próxima Página →
        </AniLink>
      )}
    </S.PaginationWrapper>
  )
}

Pagination.propTyes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  prevPage: PropTypes.string,
  nextPage: PropTypes.string,
}

export default Pagination
