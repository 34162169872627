import styled from "styled-components"
import media from "styled-media-query"

import AniLink from "gatsby-plugin-transition-link/AniLink"

export const PostItemWrapper = styled.section`
  display: flex;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const PostItemLink = styled(AniLink)`
  cursor: pointer;
  border-bottom: 5px solid var(--orange);
  display: block;
  color: var(--white);
  background-color: var(--black_background_contrast);
  margin-bottom: 2rem;
  text-decoration: none;
  max-height: 240px;

  &:hover .hover {
    background-color: var(--orange);
  }

  ${media.lessThan("large")`
    max-height: none;
  `}
`

export const PostItemBox = styled.div`
  width: ${props => (props.width ? props.width : "50%")};
  padding: ${props => (props.padding ? props.padding : "0")};

  ${media.lessThan("large")`
    width: 100%;
    display: block;
  `}
`

export const PostItemImg = styled.img`
  width: 100%;
  height: 180px;
  display: block;
  object-fit: cover;
  overflow: hidden;
`

export const PostItemCategory = styled.p`
  text-align: center;
  background-color: var(--dark_orange);
  position: relative;
  bottom: 0;
  padding: 1rem 0;
`

export const PostItemDate = styled.time`
  font-size: 0.75rem;
`

export const PostItemTitle = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
  margin: 1rem 0;
`

export const PostItemDescription = styled.p`
  font-weight: 300;
  max-height: 140px;
  overflow: hidden;
`
