import styled from "styled-components"

export const PaginationWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  padding: 1.5rem 3rem;
  background-color: var(--black_background_contrast);

  a {
    color: var(--white);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
