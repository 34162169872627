import React from "react"
import PropTypes from "prop-types"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import * as S from "./styled"

const PostItem = ({
  slug,
  category,
  date,
  timeToRead,
  title,
  description,
  image,
  altImage,
}) => {
  return (
    <S.PostItemLink
      to={slug}
      cover
      direction="right"
      bg="#08070e"
      duration={0.6}
      onClick={e => {
        e.preventDefault()
        trackCustomEvent({
          category: "Posts",
          action: "Click",
          label: slug,
        })
      }}
    >
      <S.PostItemWrapper>
        <S.PostItemBox width="30%">
          <S.PostItemImg src={`/${image}`} alt={altImage} />
          <S.PostItemCategory className="hover">{category}</S.PostItemCategory>
        </S.PostItemBox>
        <S.PostItemBox width="70%" padding="1rem">
          <S.PostItemDate>
            {date} • {timeToRead} min de leitura
          </S.PostItemDate>
          <S.PostItemTitle>{title}</S.PostItemTitle>
          <S.PostItemDescription>{description}</S.PostItemDescription>
        </S.PostItemBox>
      </S.PostItemWrapper>
    </S.PostItemLink>
  )
}

PostItem.propTypes = {
  slug: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  altImage: PropTypes.string.isRequired,
}

export default PostItem
